<template>
  <div class="tw-p-6 sm:tw-p-10">
    <div class="tw-block sm:tw-flex tw-mb-6 tw-items-center tw-justify-between">
      <h5 class="tw-text-2xl inter-semibold tw-text-black tw-mb-3 sm:tw-mb-0">
        Pesanan
      </h5>
      <div>
        <!-- <Button
          label="Impor"
          icon="pi pi-cloud-download"
          iconPos="left"
          class="tw-h-9 tw-text-sm tw-mr-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
        />
        <Button
          label="Ekspor"
          icon="pi pi-cloud-upload"
          iconPos="left"
          class="tw-h-9 tw-text-sm tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
        /> -->
      </div>
    </div>

    <form @submit.prevent="doSearch()" class="tw-mb-8 tw-flex">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          v-model="keyword"
          placeholder="Cari disini"
          class="tw-h-11 md:tw-w-80 tw-w-auto mr-3"
        />
      </span>
      <Button
        label="Filter"
        type="submit"
        icon="pi pi-align-center"
        iconPos="left"
        class="tw-h-11 tw-text-sm tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300 tw-block md:tw-hidden"
      />
    </form>

    <div class="tw-hidden sm:tw-block">
      <DataTable
        :value="invoices"
        :paginator="true"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PageLinks LastPageLink"
      >
        <template #empty>
          <div
            class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold"
          >
            Tidak ada data yang ditemukan
          </div>
        </template>
        <template #loading>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
            Loading records, please wait...
          </div>
        </template>
        <Column
          field="no"
          header="No."
          sortable
          style="min-width: 2rem"
        ></Column>
        <Column
          header="No. Invoice"
          field="invoice_number"
          sortable
          style="min-width: 10rem"
        ></Column>
        <Column
          header="Nama Salon"
          field="salon_name"
          sortable
          style="min-width: 12rem"
          v-if="isAdminPriti()"
        ></Column>
        <Column
          header="Nama Pelanggan"
          field="customer_name"
          sortable
          style="min-width: 12rem"
        ></Column>
        <Column header="Harga" field="amount" sortable style="min-width: 10rem">
          <template #body="{ data }">
            {{ formatCurrency(data.amount) }}
          </template>
        </Column>
        <Column
          header="Status"
          field="status"
          sortable
          style="min-width: 11rem"
        >
          <template #body="{ data }">
            <Tag
              class="mr-2 status status-warning"
              value="Menunggu diproses"
              v-if="data.status === 0"
            ></Tag>
            <Tag
              class="mr-2 status status-info"
              value="Belum dibayar"
              v-else-if="data.status === 1"
            ></Tag>
            <Tag
              class="mr-2 status status-success"
              value="Sudah dibayar"
              v-else-if="data.status === 2"
            ></Tag>
            <Tag
              class="status status-danger"
              value="Dibatalkan"
              v-else-if="data.status === 3"
            ></Tag>
          </template>
        </Column>
        <Column header="Aksi" style="min-width: 8rem">
          <template #body="{ data }">
            <a
              :href="`${data.id}`"
              class="tw-mr-4"
              @click.prevent="detailItem(data.id)"
            >
              <i class="pi pi-eye tw-text-lg"></i>
            </a>
            <!-- <a :href="`delete/${data.id}`" @click.prevent="deleteItem(data.id)">
              <i class="pi pi-trash"></i>
            </a> -->
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="tw-block sm:tw-hidden">
      <div
        class="card tw-p-0 tw-mb-5"
        v-for="(item, i) in card_invoices"
        :key="`item_${i}`"
      >
        <div class="tw-px-5 tw-py-3">
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize tw-mb-3">{{ item.customer_name }}</h6>
            <a href="#" @click.prevent="toogle($event, item.id)">
              <i class="pi pi-ellipsis-v"></i>
            </a>
            <Menu ref="menu" id="overlay_menu" :model="menus" :popup="true" />
          </div>
          <div class="tw-flex tw-justify-between tw-mb-1">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">
              No. Invoice
            </h6>
            <h6 class="tw-capitalize opacity-60 tw-text-sm">
              {{ item.invoice_number }}
            </h6>
          </div>
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">Status</h6>
            <div>
              <Tag
                class="status status-warning"
                value="Menunggu diproses"
                v-if="item.status === 0"
              ></Tag>
              <Tag
                class="status status-info"
                value="Belum dibayar"
                v-else-if="item.status === 1"
              ></Tag>
              <Tag
                class="status status-success"
                value="Sudah dibayar"
                v-else-if="item.status === 2"
              ></Tag>
              <Tag
                class="status status-danger"
                value="Dibatalkan"
                v-else-if="item.status === 3"
              ></Tag>
            </div>
          </div>
        </div>
        <hr />
        <div class="tw-px-5 tw-py-3">
          <h5 class="tw-text-blue-700 inter-bold">
            {{ formatCurrency(item.amount) }}
          </h5>
        </div>
      </div>

      <Paginator
        :rows="limit"
        :totalRecords="invoice.length"
        @page="changePage"
        template="FirstPageLink PageLinks LastPageLink"
      >
      </Paginator>
    </div>
  </div>
</template>

<script>
import { useAuthRole } from "@/composables/auth";

export default {
  setup() {
    const { isAdminPriti } = useAuthRole();
    return { isAdminPriti };
  },
  data() {
    return {
      keyword: "",
      invoice: [],
      invoices: [],
      card_invoices: [],
      current_id: null,
      offset: 0,
      limit: 10,
      menus: [
        {
          label: "Lihat Detail",
          icon: "pi pi-eye",
          command: () => {
            this.detailItem(this.current_id);
          },
        },
        {
          label: "Hapus",
          icon: "pi pi-trash",
          command: () => {
            this.deleteItem(this.current_id);
          },
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/invoices/list`)
        .then((response) => {
          this.invoices = response.data.data.invoices;
          this.invoice = this.invoices;
          this.invoices.forEach((item, idx) => {
            item.no = idx + 1;
          });
          this.changePage({ page: 0 });
        });
    },
    doSearch() {
      let keyword = this.keyword.toLowerCase();

      if (keyword === "menunggu diproses") {
        this.invoices = this.invoice.filter((item) => item.status === 0);
        return;
      } else if (keyword === "belum dibayar") {
        this.invoices = this.invoice.filter((item) => item.status === 1);
        return;
      } else if (keyword === "sudah dibayar" || keyword === "dibayar") {
        this.invoices = this.invoice.filter((item) => item.status === 2);
        return;
      } else if (keyword === "dibatalkan") {
        this.invoices = this.invoice.filter((item) => item.status === 3);
        return;
      }

      this.invoices = this.invoice.filter(
        (item) =>
          item.invoice_number.toLowerCase().includes(keyword) ||
          item.salon_name.toLowerCase().includes(keyword) ||
          item.amount.toString().toLowerCase().includes(keyword) ||
          (item.customer_name &&
            item.customer_name.toLowerCase().includes(keyword))
      );
      this.card_invoices = this.invoices;
      this.changePage({ page: 0 });
    },
    detailItem(id) {
      this.$router.push({
        name: "detail-invoice",
        params: { id: id },
      });
    },
    deleteItem(id) {
      // TO DO DELETE ITEM
      console.info(id);
    },
    formatCurrency(data) {
      let value = parseInt(data);
      return value.toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });
    },
    toogle(event, id) {
      this.current_id = id;
      this.$refs.menu.toggle(event);
    },
    changePage(event) {
      let page = event.page + 1;
      let offset = (page - 1) * this.limit;
      let dataLength = this.invoices.length;

      let breakPoint =
        page <= Math.floor(dataLength / this.limit)
          ? offset + this.limit
          : offset + (dataLength % this.limit);

      this.card_invoices = [];
      for (let i = offset; i < breakPoint; i++) {
        const element = this.invoices[i];
        this.card_invoices.push(element);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
